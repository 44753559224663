.donateItem {
	flex: auto;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	overflow: hidden;
	height: auto;
	border-bottom: 2px solid black;
	margin-left: 30px;
	margin-right: 30px;
	padding-bottom: 5vh;
}

.donateHeader {
	font-family: 'Shadows Into Light', cursive;
	font-weight: 400;
	font-size: 2em;
	text-align: center;
	width: 100%;
	padding-top: 3vh;
}

.donateSubHeader {
	font-family: 'Shadows Into Light', cursive;
	font-weight: 400;
	font-size: 1.2em;
	text-align: center;
	width: 100%;
	padding-bottom: 3vh;
}

.donateContent {
	margin-left: 30px;
	margin-right: 30px;
}

.donateOrg {
	margin-top: 1vh;
	margin-bottom: 1vh;
}

.donateLink {
	text-decoration: none;
	color: black;
}

.donateLink:hover {
	color: rgba(7, 0, 156, 0.7161458333333333);
}

.donateDescription {
	margin-left: 30px;
	margin-right: 30px;
}

@media only screen and (max-width: 1300px) {
	.donateContent {
		font-size: 0.9rem;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
	}
}

@media only screen and (max-width: 760px) {
	.donateHeader {
		font-size: 1.5em;
	}
}

@media only screen and (max-width: 550px) {
	.donateContent {
		margin: 0;
	}
}
