.aboutItem {
	flex: auto;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	overflow: hidden;
	height: auto;
	border-bottom: 2px solid black;
	margin-left: 30px;
	margin-right: 30px;
	padding-bottom: 5vh;
}

.aboutContent {
	border-bottom: 1px solid black;
	padding-bottom: 10vh;
	padding-top: 5vh;
}

.aboutHeader {
	margin-left: 45%;
	height: 10vh;
}

.animatedText {
	font-family: 'Shadows Into Light', cursive;
	font-weight: 400;
	display: inline-flex;
	font-size: 2em;
}

.aboutBio {
	display: inline-block;
}

.aboutInfo {
	padding-left: 30px;
	padding-right: 30px;
}

.aboutImg {
	width: 270px;
	height: 360px;
	float: right;

	/* for no radius and line between text & image */
	border-left: 1px solid black;
	padding: 30px;
	margin-left: 30px;
	margin-bottom: 30px;
}

.aboutFacts {
	margin: 30px;
	display: flex;
	flex-flow: wrap;
}

.factsHeader {
	font-family: 'Shadows Into Light', cursive;
	font-weight: 400;
	font-size: 2em;
	text-align: center;
	margin-bottom: 30px;
	width: 100%;
}

.factsContent {
	border-right: 1px solid black;
	border-bottom: 1px solid black;
	padding: 10px;
	display: inline-grid;
	width: 30%;
	min-width: 300px;
	margin-top: 10px;
	margin-left: 10px;
}

.fact {
	text-decoration: underline;
	display: grid;
}

@media only screen and (max-width: 1300px) {
	.aboutContent {
		font-size: 0.9em;
	}
	.aboutInfo {
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
	}
	.aboutHeader {
		margin-left: 30px;
	}
	.factsContent {
		font-size: 0.9em;
		min-width: 250px;
		width: 25%;
	}
}

@media only screen and (max-width: 968px) {
	.factsContent {
		width: 45%;
	}
}

@media only screen and (max-width: 760px) {
	.aboutItem {
		padding-bottom: 10vh;
	}
	.animatedText {
		font-size: 1.5em;
	}
	.aboutImg {
		border-left: none;
		border: 2px solid white;
		border-radius: 10px;
		padding: 0;
	}
	.aboutFacts {
		margin: 0;
		margin-top: 30px;
	}
	.factsHeader {
		font-size: 1.5em;
	}
	.factsContent {
		width: 40%;
	}
}

@media only screen and (max-width: 550px) {
	.aboutImg {
		float: none;
	}
	.aboutInfo {
		padding: 0;
	}
	.factsContent {
		width: 60%;
		min-width: 350px;
	}
}

@media only screen and (max-width: 400px) {
	.factsContent {
		width: 80%;
		min-width: auto;
	}
}

@media only screen and (max-width: 375px) {
	.aboutHeader {
		margin-left: 0;
	}
	.aboutImg {
		margin: 0;
	}
}

@media only screen and (max-width: 320px) {
	.aboutImg {
		width: 200px;
		height: auto;
	}
	.factsContent {
		margin: 0;
		width: 190px;
		min-width: 190px;
	}
}
