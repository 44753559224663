body {
	margin: 0;
	font-family: 'Shadows Into Light', cursive;
	font-family: 'Bebas Neue', cursive;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	background: rgb(7, 0, 156);
	background: linear-gradient(
		70deg,
		rgba(7, 0, 156, 0.7161458333333333) 0%,
		rgba(253, 132, 29, 0.8225884103641457) 20%,
		rgba(247, 247, 247, 1) 35%,
		rgba(247, 247, 247, 1) 75%,
		rgba(145, 193, 36, 0.671327906162465) 90%
	);
}

#root {
	height: auto;
	overflow-y: scroll;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
